.match-detail-content-wrapper {
  width: 100%;
  h3 {
    font-size: 2rem;
  }
}
.match-detail-video-form {
  display: flex;
  align-items: center;
}

.match-detail-video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-bottom: solid #252c32 1px;
}

.match-detail-youtube {
  width: 60%;
}

.match-detail-mvp-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-bottom: solid #252c32 1px;
}

.match-detail-mvp-input-wrapper {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.match-detail-mvp-image-wrapper {
  display: flex;
  width: 10%;
  flex-direction: column;
}

.device-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  span {
    font-family: 'Schoolbell', cursive;
    font-size: 1.2rem;
  }
  margin-bottom: 2rem;
}
