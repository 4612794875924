.post-list-page-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.post-search-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .active {
        background: #003D80;
        color: #ffffff;
        border-radius: 4px;
        padding: 9px 12px;
        cursor: default;
        border: 2px solid #003d80;
    }
}

.pagination-container button {
    &:hover {
        cursor: pointer;
        background: #3a3a3a;
        color: #ffffff;
        border-radius: 4px;
        border: 2px solid #3a3a3a;
    }
    padding: 3px 12px;
    border: 1px solid #ddd;
}