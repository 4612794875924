.player-profile-photo {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.player-profile-photo-file {
  width: 10%;
  margin-right: 1rem;
}
