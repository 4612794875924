$default-border: 2px solid #e5e5e5;

.post-detail-page-container{
    a:visited, a:link {
        color: #90caf9;
    }
}

.post-detail-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1rem auto 0;
    box-shadow: 0 0 2px 1px #3d4b55;
}

.post-detail-header {
    text-align: start;
    .post-detail-title {
        background: linear-gradient(#25323b, #080b0d);
        color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        padding: 15px 30px;
    }
}

.post-detail-wrapper {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .post-detail {
        padding: 15px 30px;
        color: #bbb;
    }
    .post-detail-author {
        font-weight: 700;
        font-size: 14px;
        color: #3a3a3a;
    }
    div {
        display: flex;
    }
}

.post-detail-content-wrapper {
    background-color: #ffffff;
    border-top: $default-border;
    .post-detail-content {
        width: 80%;
        margin: 1rem auto 0;
        text-align: start;
        * {
            max-width:100%
        }
    }
    
}

.post-detail-button-wrapper {
    margin: 1rem 0;
    button {
        margin: 0 0.5rem;
    }
    & > .post-detail-edit-button {
        padding: 0;
        min-width: 64px;
        min-height: 36px;
        & > a {
            width: 100%;
            height: 100%;
        }
    }
}