.login-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0f1519;
}

.login-button {
  color: #fff;
  border-radius: 0.5rem;
  border: #fff solid 0.5px;
  font-size: 1.2rem;
  width: 6rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  &:hover {
    color: #ff5722;
    border-color: #ff5722;
    cursor: pointer;
  }
}
