.post-item-container {
    margin: 0.3rem 0;
    color: #e0e0e0;
    border: solid #252c32 1px;
    border-radius: 4px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    &:hover {
        color: #ff5722;
        cursor: pointer;
    }
}

.post-item {
    padding: 20px;
    display: flex;
    align-items: center;
}

.post-league-datetime {
    flex-direction: column;
    justify-content: center;
    flex: 1 1 0;
    border-right: solid #252c32 1px;
    //width: 15%;
}

.post-thumbnail {
    width: 150px;
    height: 100px;
}

.post-thumbnail-title-wrapper {
    flex: 4 1 0;
}

.post-title {
    //width: 70%;
    text-align: left;
    border-right: solid #252c32 1px;
    flex: 4 1 0;
}

.post-author {
    //width: 15%;
    display: flex;
    justify-content: center;
    flex: 1 1 0;
}