.match-list-navigator-wrapper {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 2.5rem;
}

.match-list-navigator-item {
  margin: 0.5rem 1.5rem;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #ff5722;
    cursor: pointer;
  }
}

.match-list-navigator-item-disabled {
  margin: 0.5rem 1.5rem;
  text-decoration: none;
  color: #fff;
  pointer-events: none;
}

.match-list-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  color: #fff;
  background-color: #0f1519;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
