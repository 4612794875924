.user-search-wrapper {
    color: white;
}

.user-search-result-table-wrapper {
    display: flex;
    justify-content: center;

    table {
        border-spacing: 0.3rem;
    }

    .search-table-header {
        padding-bottom: 10px;
        font-size: 1.15rem;
    }
}