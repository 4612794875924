.league-search-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid #252c32 1px;

    .input-wrapper {
        border-bottom: none;
    }

    table {
        width: 90%;
        .MuiInputBase-root {
            width: 100%;
        }
    }
}