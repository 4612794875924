.post-editor-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto 0;
}

.post-thumbnail-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    color: #ffffff;
    .post-thumbnail-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
    .post-thumbnail {
        display: flex;
        align-items: center;
        & > img {
            width: 150px;
            height: 100px;
        }
    }
}

.post-title-status-wrapper {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}

.uploaded-image-file-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}

.uploaded-images-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0.5rem;
    border: solid #858585 1px;
    border-radius: 4px;
    div:empty {
        width: 70%;
        padding: 50px;
    }
}

.uploaded-image-files-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

.uploaded-images-text {
    padding: 5vw;
    color: #858585;
    border-right: solid #858585 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .uploaded-images-container {
        flex-direction: column;
    }
    .uploaded-images-text {
        border-right: none;
        border-bottom: solid #858585 1px;
    }
    .uploaded-image-files-container {
        display: flex;
        flex-direction: column;
    }
    .uploaded-image-file-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }
}