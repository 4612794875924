.match-detail-players-list {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    gap: 4rem;
}

.match-detail-home-team, .match-detail-away-team {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.generator-title {
    font-size: 3rem;
    font-weight: 700;
}