html {
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 16px;
  }
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #0f1519;
  min-height: 100vh;
  height: 100%;
  // height: 100vh;
}

@font-face {
	font-family: "Anton";
	src: url("./fonts/Anton-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Antonio";
  src: url("./fonts/Antonio-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: url('./fonts/Montserrat-BlackItalic.ttf') format('truetype');
}