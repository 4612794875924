.team-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: solid #252c32 1px;
    &:hover {
      color: #ff5722;
      cursor: pointer;
    }
  }
  
  .team-name {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    color: #e0f0ff
  }
  