.player-search-container {
    color: white;
    border-top: solid #252c32 3px;
    width: 90%;
}

.player-search-wrapper {
    margin: 0 auto;
    width: 75%;
}

.player-search-result-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.player-search-result {
    display: flex;
    justify-content: flex-start;
    .player-search-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.3rem;
    }
    .searched-players{
        flex: 1;
        align-items: center;
    }
    .player-search-teams span {
        font-weight: 500;
        margin-right: 0.5rem;
    }
}