.drag-and-drop-box{
    display: flex;
    width: 50%;
    height: 8rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 0.5rem solid black;
    border-radius: 2px;
    border-color: #827b7b;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;

    p {
        color:#252c32; 
        font-family: 'Schoolbell', cursive;
        font-size: 2.2rem;
    }
}

.drag-and-drop-box:hover {
    border-style: solid;
    border-color: #827b7b;

}

.file-item{
    display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        grid-template-areas:
            "image1 image1 image1 image1"
            "text text text text";
}

.image-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Adjust the number of columns as needed */
    gap: 10px;
    /* Add spacing between grid items */
    border: 1px solid #ccc;
    /* Add border to the grid container */
    padding: 10px;
    /* Add padding to the grid container */
}

.image-wrapper {
    position: relative;
}

.image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background-color: grey;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.delete-button:hover {
    background-color: red;
}

.upload-button{
    width: 100%;
    height: 5rem;
    border-radius: 5px;
    margin-bottom: 15rem;
    font-size: 2rem;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.upload-button:hover {
    background-color: green;
}