.starting-five {
    width: 1080px;
    height: 1080px;
    background-size: cover;
}

.starting-five-wrapper {
    font-family: 'Anton';
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 5rem 0;
    .starting-match-name-wrapper {
        width: 98.8%;
        height: 16.5%;
        .starting-match-name {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 125px;
            line-height: 1;
        }
    }
}

.starting-team-names {
    padding-top:24%;
    height: 13.5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
    font-size: 60px;
    .starting-home-team-name {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .starting-away-team-name {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.starting-five-players {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 46%;
}

.home-starting-five, .away-starting-five {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    font-size: 40px;
    width: 85%;
    margin: 0 7.5%;
    align-items: center;
    .starting-five-player {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .PG {
        grid-row: 1 / 2;
    }
    .SG {
        grid-row: 2 / 3;
    }
    .SF {
        grid-row: 3 / 4;
    }
    .PF {
        grid-row: 4 / 5;
    }
    .C {
        grid-row: 5 / 6;
    }
}

.starting-five-player-position {
    width: 20%;
    font-size: 60px;
}

.starting-five-player-name-wrapper {
    width: 100%;
    .starting-five-player-name {
        display: flex;
        justify-content: center;
    }
}