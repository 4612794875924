.player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-wrapper {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;

  border-bottom: solid #252c32 1px;
  color: #fff;
}

.MuiFormControl-root {
  margin: 1rem 0.5rem !important;
}

.create-player-button {
  color: #fff;
  border-radius: 0.5rem;
  border: #fff solid 1px;
  font-size: 1.2rem;
  width: 12rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  &:hover {
    color: #ff5722;
    border-color: #ff5722;
    cursor: pointer;
  }
}

.season-player-update-container{
  margin-top: 1rem;
  color: #fff;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-bottom: solid #252c32 1px;
}

.player-detail-cotainer {
  margin-top: 1rem;
  color: #fff;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-bottom: solid #252c32 1px;
}

.player-detail-tables {
  max-width: 1800x;
  width: 95%;
}

.player-create-cotainer {
  margin-top: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid #252c32 3px;
}

.player-create-button {
  margin: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}