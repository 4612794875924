.match-detail-home-shooting-chart,
.match-detail-away-shooting-chart {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  width: 100%;
  color: #fff;
  background-color: #0f1519;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.match-detail-shooting-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-detail-shooting-chart-file-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  canvas {
    display: none;
    width: 100%;
  }
  .match-detail-shooting-chart-file {
    width: 100%;
  }
}

.shooting-chart-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .uploaded-image-wrapper, .canvas-wrapper {
    font-family: 'Schoolbell', cursive;
    font-size: 1.5rem;
    width: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
