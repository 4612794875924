.season-roster-register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-wrapper {
        border: none;
    }
}

.season-roster-upload {
    display: flex;
    flex-direction: column;
}