.match-live-wrapper {
  height: calc(100vh - 5rem);
  color: #fff;
  display: flex;
  flex-direction: column;
}

.match-live-content {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 2.5fr 1fr;
}

.match-live-home {
  border-right: solid #252c32 1px;
  display: flex;
  flex-direction: column;
}

.match-live-away {
  border-left: solid #252c32 1px;
  display: flex;
  flex-direction: column;
}

.cell-back-number {
  width: 2rem;
}

.cell-player-name {
  text-align: left;
}

td {
  padding: 0.5rem;
}

.match-live-control-wrapper {
  display: flex;
  flex-direction: column;
}
.match-live-score-log {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: solid #252c32 1px;
}

.control-log-wrapper {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  border-right: solid #252c32 1px;
}

.control-score-borrd {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 4rem;
}

.match-live-control {
  display: grid;
  column-gap: 1rem;
  margin: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
}

.control-item-default {
  height: 7rem;
  color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}

.control-item {
  background-color: #135e96;
}

.control-item-success {
  background-color: #007017;
}

.control-item-fail {
  background-color: #b32d2e;
}
