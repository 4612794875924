.team-create-wrapper {
  margin: 1rem;
  text-align: center;
  color: white;
  min-height: calc(100vh - 64px); // Adjust for header height
  background-color: #0f1519;

  h2 {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
} 