.thumbnail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
    gap: 1rem;
    font-family: 'Antonio';
}

.thumbnail {
    width: 1280px;
    height: 720px;
}

.thumbnail-match-name-wrapper {
    padding-top: 8%;
    font-family: 'Montserrat-BlackItalic';
    display: flex;
    justify-content: flex-end;
    padding-right: 6.5%;
    line-height: 1;
    text-shadow: 7px 7px rgba(107, 107, 107, 0.5);
}

.thumbnail-match-name {
    font-size: 160px;
}

.thumbnail-match-name-playoff {
    display: flex;
    flex-direction: column;
    font-size: 85px;
    text-shadow: 4px 4px rgba(107, 107, 107, 0.5);
}

.thumbnail-teams {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25%;
    img {
        width: 250px;
        height: auto;
        max-height: 250px;
    }
    * {
        position: absolute;
        transform: translate(-50%, -50%);
    }
}

.thumbnail-home-team-logo {
    left: 26%;
    top: 150px;
}

.thumbnail-home-team-name {
    left: 26%;
    top: 330px;
}

.thumbnail-away-team-logo {
    left: 75%;
    top: 150px;
}

.thumbnail-away-team-name {
    left: 75%;
    top: 330px;
}

.thumbnail-team-name {
    width: 50%;
    font-style: italic;
    font-size: 58px;
    text-shadow: 2px 5px 7px rgba(0, 0, 0, 0.5);
}
