.header-wrapper-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
  .header-wrapper {
    background-color: #0f1519;
    border-bottom: solid #252c32 1px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }
}

.logo-wrapper {
  &:hover {
    cursor: pointer;
  }
}

.Header-logo {
  padding: 0 1rem;
  height: 2.5rem;
  width: auto;
}

.header-item {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow: #ff5722 0px -2px 0px inset;
  }
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  color: #fff;
  font-weight: 700;
}
