.connection-table-container {
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connection-table {
    max-width: 1800x;
    width: 80%;
    tbody {
        th {
            border-bottom: 1px solid #252c32;
        }
    }
    th {
        height: 50px;
    }
}

.button-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.625rem;
}