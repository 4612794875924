.match-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: solid #252c32 1px;
  &:hover {
    color: #ff5722;
    cursor: pointer;
  }
}

.match-date-day {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.match-league-game-name {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.match-time {
  display: flex;
  justify-content: center;
}

.match-team-with-score {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.match-score-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
