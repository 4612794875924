.team-detail-captains-container {
    width: 100%;
    border-bottom: 1px solid #252c32;
}

.team-detail-captains {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    .team-captains-list, .team-captains-add {
        width: 100%;
    }
}

.team-captains-list {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.team-captains-list-title, .team-captain-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.team-captains-add {
    border-left: 1px solid #252c32;
}