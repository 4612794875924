.match-detail-stat-cotainer {
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-detail-stat-table {
  max-width: 1800x;
  width: 95%;
}
