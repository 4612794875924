.team-detail-wrapper {
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-detail-control-box {
  padding: 1rem;
  border-bottom: solid #252c32 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.team-name {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.team-detail-upload-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
}

.team-stat-navigation-wrapper {
  display: flex;
  justify-content: space-around;
  width: 30rem;
}

.team-stat-navigator {
  text-decoration: none;
  color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
  font-weight: 600;
  &:hover {
    color: #ff5722;
  }
  border-bottom: solid #fff 1px;
}

.team-logo {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: center;
}

.team-season-enroll{
  display:flex;
}

.input-wrapper {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;

  border-bottom: solid #252c32 1px;
  color: #fff;
}