.match-detail-wrapper {
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-league-game-name {
  display: flex;
  flex-direction: column;
  span {
    padding: 0.5rem;
  }
}

.match-name-wrapper, .match-location-wrapper, .match-deadline-wrapper {
  display: flex;
  align-items: center;
}

.match-detail-score-wrapper {
  padding: 1rem;
  border-bottom: solid #252c32 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.match-detail-forfeit-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
}

.match-detail-delete-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
}

.match-stat-navigation-wrapper {
  display: flex;
  justify-content: space-around;
  width: 30rem;
}

.match-stat-navigator {
  text-decoration: none;
  color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
  font-weight: 600;
  &:hover {
    color: #ff5722;
  }
  border-bottom: solid #fff 1px;
}

.match-detail-email-notification-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30rem;
}

.winner-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  color: #ffd700;  // Gold color
  
  span {
    font-size: 12px;
    font-weight: bold;
  }
}

.match-detail-sync-winner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
  margin-top: 1rem;
}