.create-match-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-wrapper {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;

  border-bottom: solid #252c32 1px;
  color: #fff;
}

.MuiFormControl-root {
  margin: 1rem 0.5rem !important;
}

.create-match-button {
  color: #fff;
  border-radius: 0.5rem;
  border: #fff solid 1px;
  font-size: 1.2rem;
  width: 6rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  &:hover {
    color: #ff5722;
    border-color: #ff5722;
    cursor: pointer;
  }
}
